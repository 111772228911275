<template>
  <div style="white-space: nowrap">
    <v-icon
      :color="item.reports[documentType] ? 'success' : 'error'"
      left
      small
      class="mx-0"
    >
      {{ item.reports[documentType] ? 'check' : 'clear' }}
    </v-icon>
    <span class="hidden-lg-and-down">
      {{ item.reports[documentType] ? new Date(item.reports[documentType].date).toLocaleDateString($vuetify.lang.current) : '---' }}
    </span>
  </div>
</template>
<script>
  export default {
    name: 'FundTableDocumentField',
    props: {
      item: {
        type: Object,
        required: true,
      },
      documentType: {
        type: String,
        required: true,
      },
    },
  }
</script>
